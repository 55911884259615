import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './Homepage/homepage';
import Register from './Register/register';
import Info from './Register/Info';
import Feedback from './feedback/feedback';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/register' element={<Register />} />
          {/* <Route path='/info' element={<Info />} /> */}
          {/* <Route path='/feedback' element={<Feedback />} /> */}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
